div.Triangle-right-metric {
    display: table;
    height: auto;
    overflow: hidden;
}

div.Triangle-right-metric span {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
}

img.flip {
    transform: scaleY(-1);
}